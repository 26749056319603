/* You can add global styles to this file, and also import other style files */
@import '~quill/dist/quill.snow.css';

html,
body {
  min-height: 100vh;
  background-color: var(--color-neutral-99);
  height: 100%;

  /* Altera a largura da barra de rolagem */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Altera a cor de fundo da barra de rolagem */
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
  }

  /* Altera a cor da alça da barra de rolagem */
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-primary-30);
    border-radius: 10px;
  }

  /* Altera a cor quando a alça da barra de rolagem é hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-primary-10);
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--font-family-helvetica), sans-serif;
}

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./theme.scss";
@import "./wrapper.scss";

$primary: #276EF1;

h5 {
  margin-bottom: 8px;
}

mat-list.clickable {
  mat-list-item {
    transition: background-color 0.4s;

    &:hover {
      cursor: pointer;
      background-color: rgb(245, 236, 224);
    }
  }

  mat-list-item.noclick {
    transition: background-color 0.4s;

    &:hover {
      cursor: default;
      background-color: transparent;
    }
  }
}

mat-list.small {
  mat-list-item {
    height: 28px !important;

    p {
      font-size: 12px !important;
    }

    mat-icon,
    .icon-rep {
      margin-left: 0px !important;
      margin-right: 8px !important;

      .mat-badge-content {
        width: 12px !important;
        height: 12px !important;
        line-height: 12px !important;
        right: -2px !important;
        bottom: -2px !important;
        font-size: 10px !important;
      }

      .badge {
        position: absolute;
      }
    }
  }
}

.infoblock {

  p,
  a {
    font-size: 0.8em;
    display: block;
    margin-bottom: 4px;

    strong {
      font-weight: 500;
    }
  }
}

button {
  outline: none;
}

a {
  color: var(--color-tertiary-40);
  font-weight: bold;
  text-decoration: none;
}

button {
  cursor: pointer;
}

//steps//

.mat-stepper-label-position-bottom.mat-horizontal-stepper-header:not( :first-child)::before,
[dir="rtl"] .mat-stepper-label-position-bottom.mat-horizontal-stepper-header:not( :last-child)::before {
  left: auto !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
  border: 1px solid var(--color-black);
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px;
  background-color: var(--color-white);
  margin: 0px 10px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before {
  left: auto !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
  right: auto !important;
}

.mat-step-icon.mat-step-icon-state-number {
  font-weight: 700;
  font-size: 18px;
  line-height: 60%;
  background-color: transparent !important;
  color: var(--color-disabled);
}

.mat-step-header .mat-step-icon-selected {
  font-weight: 700;
  font-size: 18px;
  line-height: 60%;
  background-color: transparent;
  color: var(--color-primary-30);
}

.mat-step-label {
  padding: 0px !important;

  .mat-step-text-label {
    @extend .sub-2;
    color: var(--color-disabled);
  }

  &.mat-step-label-selected {
    .mat-step-text-label {
      @extend .sub-2;
      color: var(--color-black);
    }
  }
}

.mat-horizontal-content-container {
  margin-top: 10px;
}

.red-snackbar {
  --mdc-snackbar-container-color: var(--color-danger) !important;
}

.mat-mdc-menu-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-mdc-menu-panel {
  margin-top: 10px !important;
}

.mat-mdc-menu-panel.drop-btn-menu {
  width: 180px !important;
}

.mat-mdc-menu-content .mdc-list-item__primary-text {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-family: var(--font-family-helvetica), sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: var(--color-neutral-40) !important;
}

.mat-mdc-menu-content .mat-mdc-menu-item {
  min-height: auto !important;
}

.btn-flat {
  background-color: var(--color-primary-30);
  color: var(--color-white);
  font-family: var(--font-family-helvetica);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
}

markdown {
  p {
    margin-bottom: 10px;
  }

  br {
    height: 16px;
  }

  h3 {
    font-size: 22px;
    margin-bottom: 8px;
    margin-top: 25px;
  }

  li {
    margin-bottom: 8px;
  }
}

.oa-dialog {
  box-sizing: border-box;
  display: block;
  // height: clamp(300px, 50vh, 800px);
}

@mixin def-button {
  box-sizing: border-box;
  background-color: var(--color-neutral-10);
  color: white;
  min-width: 150px;
  margin: 0;
  padding: 10px 16px;
  border-radius: 14px;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  //styleName: Label/Large;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;

  &:hover {
    background-color: var(--color-neutral-40);
  }

  &:disabled {
    background-color: var(--color-neutral-60);
  }
}

.def-button {
  @include def-button;
}


.container-fluid {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .container-fluid {
    width: calc(540px);
  }
}

@media (min-width: 768px) {
  .container-fluid {
    width: calc(720px);
  }
}

@media (min-width: 992px) {
  .container-fluid {
    width: calc(960px - var(--nav-width));
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    width: calc(1140px - var(--nav-width));
  }
}

@media (min-width: 1400px) {
  .container-fluid {
    width: calc(1320px - var(--nav-width));
  }
}

.def-button {
  @include def-button;

  &.alt {
    background-color: var(--color-neutral-99);
    color: var(--color-neutral-10);
    border: 1px solid var(--color-neutral-70);
  }
}