@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@font-face {
  font-family: "Aeonik";
  src: url("assets/fonts/aeonik/AeonikTRIAL-Regular.otf");
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("assets/fonts/helvetica-neue-5/HelveticaNeueMedium.otf");
}



@font-face {
  font-family: "Bodoni Book";
  src: url("assets/fonts/bodoni-book/Bodoni-Book.otf");
}

@font-face {
  font-family: "Bodoni";
  src: url("assets/fonts/bodoni/BodoniFLF-Bold.ttf");
}

body {
  font-family: var(--font-family-open-sans), sans-serif;

  /*SHARED*/
  .flex {
    display: flex !important;

    &.flex-direction-column {
      flex-direction: column !important;
    }

    &.flex-direction-row {
      flex-direction: row !important;
    }

    &.align-itens-center {
      align-items: center !important;
    }

    &.align-itens-baseline {
      align-items: baseline !important;
    }

    &.align-itens-end {
      align-items: flex-end !important;
    }

    &.align-itens-start {
      align-items: flex-start !important;
    }

    &.align-self-center {
      align-self: center !important;
    }

    &.align-self-baseline {
      align-self: baseline !important;
    }

    &.align-self-end {
      align-self: flex-end !important;
    }

    &.align-self-start {
      align-self: flex-start !important;
    }

    &.justify-content-center {
      justify-content: center;
    }

    &.justify-content-baseline {
      justify-content: baseline;
    }

    &.justify-content-space-between {
      justify-content: space-between;
    }

    &.justify-content-space-around {
      justify-content: space-around;
    }

    &.justify-content-start {
      justify-content: start;
    }

    &.justify-content-end {
      justify-content: end;
    }
  }

  .h-100 {
    height: 100% !important;
  }

  .h-auto {
    height: auto !important;
  }

  .w-100 {
    width: 100% !important;
  }

  .w-auto {
    width: auto !important;
  }

  --nav-width: 72px;
  --nav-width-open: 180px;
  --header-height: 72px;

  /*COLOR*/
  --color-priority-1: var(--color-black) 000;
  --color-priority-2: #fffe55;
  --color-priority-3: #fe4513;
  --color-priority-4: #c2fdff;
  --color-priority-5: #ffffff;
  --color-priority-6: #bebebe;
  --color-priority-7: #F8F8F8;
  --color-priority-8: #E11900;
  --color-priority-9: #FFC043;

  --color-action: #408CFF;

  --color-secundary-gray: rgba(0, 0, 0, 0.5);

  --color-secondary-blue: #488cff;
  --color-tertiary-blue: #064961;
  --color-support-blue: #83cfe9;

  --color-sucess: var(--color-secondary-40);
  --color-warning: var(--color-primary-50);
  --color-danger: var(--color-primary-20);
  --color-disabled: var(--color-neutral-70);

  $primary: #408CFF;

  --color-primary-10: #7B0200;
  --color-primary-20: #E30512;
  --color-primary-30: #E14313;
  --color-primary-40: #F58024;
  --color-primary-50: #F8B02A;

  --color-secondary-10: #7E55DD;
  --color-secondary-20: #C1ADEF;
  --color-secondary-30: #E8E0F9;
  --color-secondary-40: #3CAC44;
  --color-secondary-50: #A8DBAC;
  --color-secondary-60: #DCF9DF;
  --color-secondary-70: #FCB600;
  --color-secondary-80: #FFE9B1;
  --color-secondary-90: #FFF7E2;

  --color-tertiary-10: #FF94AE;
  --color-tertiary-20: #FFCEDA;
  --color-tertiary-30: #FFEDF1;
  --color-tertiary-40: #408CFF;
  --color-tertiary-50: #A3C8FF;
  --color-tertiary-60: #E0ECFF;

  --color-error-40: #B3261E;
  --color-error-50: #DC362E;
  --color-error-90: #F9DEDC;
  --color-error-95: #FCEEEE;
  --color-error-99: #FFFBF9;

  --color-neutral-10: #1A1E1F;
  --color-neutral-40: #70767E;
  --color-neutral-60: #9A9FA5;
  --color-neutral-70: #E4E4E4;
  --color-neutral-95: #EDEDED;
  --color-neutral-99: #F5F5F5;

  --color-black: #000000;
  --color-white: #FFFFFF;


  /*SHAPES*/
  --mdc-dialog-container-shape: 24px;

  /*SPACE*/
  --space-xx-sm: 2px;
  --space-x-sm: 4px;
  --space-sm: 8px;
  --space-md: 16px;
  --space-lg: 32px;
  --space-x-lg: 48px;
  --space-xx-lg: 64px;

  --space-stack-xx-sm: 0 0 2px 0;
  --space-stack-x-sm: 0 0 4px 0;
  --space-stack-sm: 0 0 8px 0;
  --space-stack-md: 0 0 16px 0;
  --space-stack-lg: 0 0 32px 0;
  --space-stack-x-lg: 0 0 48px 0;
  --space-stack-xx-lg: 0 0 64px 0;

  --space-inline-xx-sm: 0 2px 0 0;
  --space-inline-x-sm: 0 4px 0 0;
  --space-inline-sm: 0 8px 0 0;
  --space-inline-md: 0 16px 0 0;
  --space-inline-lg: 0 32px 0 0;
  --space-inline-x-lg: 0 48px 0 0;
  --space-inline-xx-lg: 0 64px 0 0;

  --space-inset-xx-sm: 2px 2px 2px 2px;
  --space-inset-x-sm: 4px 4px 4px 4px;
  --space-inset-sm: 8px 8px 8px 8px;
  --space-inset-md: 16px 16px 16px 16px;
  --space-inset-lg: 32px 32px 32px 32px;
  --space-inset-x-lg: 48px 48px 48px 48px;
  --space-inset-xx-lg: 64px 64px 64px 64px;

  --space-inset-squish-sm: 4px 8px 4px 8px;
  --space-inset-squish-md: 8px 16px 8px 16px;
  --space-inset-squish-lg: 16px 32px 16px 32px;

  --space-inset-strech-sm: 12px 8px 12px 8px;
  --space-inset-strech-md: 24px 16px 24px 16px;

  /*GRID*/
  --app-max-width: 1240px;

  app-firm {
    --app-max-width: 1720px;
  }

  .gap-container {
    width: 100%;
    padding: 32px;
    max-width: var(--app-max-width);
    margin: 0 auto;

    @media screen and (max-width: 990px) {
      padding: 20px 0;
    }

    &.full {
      max-width: 100%;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    [class*="col-"] {
      min-width: 0;

      &>* {
        margin: var(--space-md) var(--space-sm);
      }
    }

    .col {
      &-2 {
        flex: 0 25%;
      }

      &-4 {
        flex: 0 50%;
      }

      &-6 {
        flex: 0 75%;
      }

      &-8 {
        flex: 0 100%;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .col-sm {
      &-2 {
        flex: 0 25% !important;
      }

      &-4 {
        flex: 0 50% !important;
      }

      &-6 {
        flex: 0 75% !important;
      }

      &-8 {
        flex: 0 100% !important;
      }
    }
  }

  @media screen and (max-width: 768px) and (min-width: 576px) {
    .col-md {
      &-2 {
        flex: 0 25% !important;
      }

      &-4 {
        flex: 0 50% !important;
      }

      &-6 {
        flex: 0 75% !important;
      }

      &-8 {
        flex: 0 100% !important;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .col-lg {
      &-2 {
        flex: 0 25% !important;
      }

      &-4 {
        flex: 0 50% !important;
      }

      &-6 {
        flex: 0 75% !important;
      }

      &-8 {
        flex: 0 100% !important;
      }
    }
  }

  /*TYPOGRAPH*/
  --font-family-open-sans: "Open Sans";
  --font-family-roboto: "Roboto";
  --font-family-aeonik: "Aeonik";
  --font-family-helvetica: "Helvetica Neue";
  --font-family-bodoni-book: "Bodoni Book";
  --font-family-bodoni: "Bodoni";

  .font-open-sans {
    font-family: var(--font-family-open-sans);
  }

  .font-roboto {
    font-family: var(--font-family-roboto);
  }

  .font-aeonik {
    font-family: var(--font-family-aeonik);
  }

  .font-helvetica {
    font-family: var(--font-family-helvetica);
  }

  .font-bodoni-book {
    font-family: var(--font-family-bodoni-book);
  }

  .font-bodoni {
    font-family: var(--font-family-bodoni);
  }

  p {
    margin: 0;
  }

  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 96px;
    letter-spacing: -0.015em;
    margin: 0;
  }

  h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    letter-spacing: 0.005em;
    margin: 0;
  }

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    margin: 0;
  }

  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    margin: 0;
  }

  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    margin: 0;
  }

  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.0015em;
    margin: 0;
  }

  .sub-1 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.0015em;
  }

  .sub-2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.001em;
  }

  .body-1 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.005em;
  }

  .body-2 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.005em;
  }

  button,
  .btn {
    font-family: var(--font-family-helvetica) !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 146.5% !important;
    letter-spacing: 0.0125em !important;
  }

  .caption {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.004em;
  }

  .overline {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0.015em;
  }

  .special-button {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.015em;
  }

  /*ICON*/

  --icon-home: url("assets/icons/icon-home.svg");
  --icon-user: url("assets/icons/icon-user.svg");
  --icon-plugin: url("assets/icons/icon-plugin.svg");
  --icon-content: url("assets/icons/icon-content.svg");
  --icon-group: url("assets/icons/icon-group.svg");
  --icon-exit: url("assets/icons/icon-exit.svg");
  --icon-star: url("assets/icons/icon-star.svg");
  --icon-upload: url("assets/icons/icon-upload.svg");
  --icon-mark: url("assets/icons/icon-mark.svg");
  --icon-arrow-left: url("assets/icons/icon-arrow-left.svg");
  --icon-arrow-right: url("assets/icons/icon-arrow-right.svg");
  --icon-arrow-up: url("assets/icons/icon-arrow-up.svg");
  --icon-arrow-down: url("assets/icons/icon-arrow-down.svg");
  --icon-minus: url("assets/icons/icon-minus.svg");
  --icon-plus: url("assets/icons/icon-plus.svg");
  --icon-question: url("assets/icons/icon-question.svg");
  --icon-click: url("assets/icons/icon-click.svg");
  --icon-cancel-rd: url("assets/icons/icon-cancel-rd.svg");
  --icon-map-point: url("assets/icons/icon-map-point.svg");
  --icon-download: url("assets/icons/icon-download.svg");
  --icon-bell: url("assets/icons/icon-bell.svg");
  --icon-forward: url("assets/icons/icon-forward.svg");
  --icon-bell-rd: url("assets/icons/icon-bell-rd.svg");
  --icon-confirm-rd: url("assets/icons/icon-confirm-rd.svg");
  --icon-alert: url("assets/icons/icon-alert.svg");
  --icon-refresh-rd: url("assets/icons/icon-refresh-rd.svg");
  --icon-search: url("assets/icons/icon-search.svg");
  --icon-3-line: url("assets/icons/icon-3-line.svg");
  --icon-2-line: url("assets/icons/icon-2-line.svg");
  --icon-bar-chart: url("assets/icons/icon-bar-chart.svg");
  --icon-bar-chart-white: url("assets/icons/icon-bar-chart-white.svg");
  --icon-linkedin: url("assets/icons/icon-linkedin.svg");
  --icon-instagram: url("assets/icons/icon-instagram.svg");
  --icon-facebook: url("assets/icons/icon-facebook.svg");
  --icon-twitter: url("assets/icons/icon-twitter.svg");
  --icon-refresh: url("assets/icons/icon-refresh.svg");
  --icon-dump: url("assets/icons/icon-dump.svg");
  --icon-telephone: url("assets/icons/icon-telephone.svg");
  --icon-enter: url("assets/icons/icon-enter.svg");
  --icon-edit: url("assets/icons/icon-edit.svg");
  --icon-mail-box: url("assets/icons/icon-mail-box.svg");
  --icon-mail-box-notification: url("assets/icons/icon-mail-box-notification.svg");
  --icon-config: url("assets/icons/icon-config.svg");
  --icon-3-point: url("assets/icons/icon-3-point.svg");
  --icon-link: url("assets/icons/icon-link.svg");
  --icon-login-user: url("assets/icons/icon-login-user.svg");
  --icon-institution: url("assets/icons/icon-institution.svg");
  --icon-clipboard: url("assets/icons/icon-clipboard.svg");
  --icon-share: url("assets/icons/icon-share.svg");
  --icon-cancel: url("assets/icons/icon-cancel.svg");
  --icon-confirm: url("assets/icons/icon-confirm.svg");
  --icon-hammer: url("assets/icons/icon-hammer.svg");
  --icon-calendar: url("assets/icons/icon-calendar.svg");
  --icon-clock: url("assets/icons/icon-clock.svg");
  --icon-bell-notification: url("assets/icons/icon-bell-notification.svg");
  --icon-home-white: url("assets/icons/icon-home-white.svg");
  --icon-bag-blue: url("assets/icons/icon-bag-blue.svg");
  --icon-beer-blue: url("assets/icons/icon-beer-blue.svg");
  --icon-group-white: url("assets/icons/icon-group-white.svg");
  --icon-pet-green: url("assets/icons/icon-pet-green.svg");
  --icon-cancel-white: url("assets/icons/icon-cancel-white.svg");
  --icon-tools-white: url("assets/icons/icon-tools-white.svg");
  --icon-minus-red-rd: url("assets/icons/icon-minus-red-rd.svg");
  --icon-dump-red: url("assets/icons/icon-dump-red.svg");
  --icon-hammer-tools: url("assets/icons/icon-hammer-tools.svg");
  --icon-bell-yellow-rd: url("assets/icons/icon-bell-yellow-rd.svg");
  --icon-bell-white-rd: url("assets/icons/icon-bell-white-rd.svg");
  --icon-alert-red-rd: url("assets/icons/icon-alert-red-rd.svg");
  --icon-alert-yellow-rd: url("assets/icons/icon-alert-yellow-rd.svg");
  --icon-bell-notification-blue-rd: url("assets/icons/icon-bell-notification-blue-rd.svg");
  --icon-cancel-red-rd: url("assets/icons/icon-cancel-red-rd.svg");
  --icon-confirm-green-rd: url("assets/icons/icon-confirm-green-rd.svg");
  --icon-confirm-gray-rd: url("assets/icons/icon-confirm-gray-rd.svg");
  --icon-clock-gray: url("assets/icons/icon-clock-gray.svg");
  --icon-3-line-white: url("assets/icons/icon-3-line-white.svg");
  --icon-hammer-white: url("assets/icons/icon-hammer-white.svg");
  --icon-question-white: url("assets/icons/icon-question-white.svg");
  --icon-content-white: url("assets/icons/icon-content-white.svg");
  --icon-client-white: url("assets/icons/icon-client-white.svg");
  --icon-client: url("assets/icons/icon-client.svg");
  --icon-user-white: url("assets/icons/icon-user-white.svg");
  --icon-plus-white-rd: url("assets/icons/icon-plus-white-rd.svg");
  --icon-cancel-white-rd: url("assets/icons/icon-cancel-white-rd.svg");
  --icon-exit-blue: url("assets/icons/icon-exit-blue.svg");
  --icon-bell-blue-rd: url("assets/icons/icon-bell-blue-rd.svg");
  --icon-bar-chart-priority-4: url("assets/icons/icon-bar-chart-priority-4.svg");
  --icon-alert-white-rd: url("assets/icons/icon-alert-white-rd.svg");
  --icon-bell-notification-white-rd: url("assets/icons/icon-bell-notification-white-rd.svg");
  //--icon-bell-notification-blue-rd: url("assets/icons/icon-bell-notification-blue-rd.svg");

  .ic {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 5px;

    &-x-sm {
      @extend .ic;
      min-width: 12px;
      min-height: 12px;
    }

    &-sm {
      @extend .ic;
      min-width: 24px;
      min-height: 24px;
    }

    &-md {
      @extend .ic;
      min-width: 36px;
      min-height: 36px;
    }

    &-lg {
      @extend .ic;
      width: 48px;
      height: 48px;
    }

    &-home {
      background-image: var(--icon-home);
    }

    &-user {
      background-image: var(--icon-user);
    }

    &-plugin {
      background-image: var(--icon-plugin);
    }

    &-content {
      background-image: var(--icon-content);
    }

    &-group {
      background-image: var(--icon-group);
    }

    &-exit {
      background-image: var(--icon-exit);
    }

    &-star {
      background-image: var(--icon-star);
    }

    &-upload {
      background-image: var(--icon-upload);
    }

    &-mark {
      background-image: var(--icon-mark);
    }

    &-arrow-left {
      background-image: var(--icon-arrow-left);
    }

    &-arrow-right {
      background-image: var(--icon-arrow-right);
    }

    &-arrow-up {
      background-image: var(--icon-arrow-up);
    }

    &-arrow-down {
      background-image: var(--icon-arrow-down);
    }

    &-minus {
      background-image: var(--icon-minus);
    }

    &-plus {
      background-image: var(--icon-plus);
    }

    &-question {
      background-image: var(--icon-question);
    }

    &-click {
      background-image: var(--icon-click);
    }

    &-map-point {
      background-image: var(--icon-map-point);
    }

    &-download {
      background-image: var(--icon-download);
    }

    &-bell {
      background-image: var(--icon-bell);
    }

    &-forward {
      background-image: var(--icon-forward);
    }

    &-bell-rd {
      background-image: var(--icon-bell-rd);
    }

    &-alert {
      background-image: var(--icon-alert);
    }

    &-search {
      background-image: var(--icon-search);
    }

    &-3-line {
      background-image: var(--icon-3-line);
    }

    &-2-line {
      background-image: var(--icon-2-line);
    }

    &-bar-chart {
      background-image: var(--icon-bar-chart);
    }

    &-bar-chart-white {
      background-image: var(--icon-bar-chart-white);
    }

    &-link {
      background-image: var(--icon-link);
    }

    &-linkedin {
      background-image: var(--icon-linkedin);
    }

    &-facebook {
      background-image: var(--icon-facebook);
    }

    &-instagram {
      background-image: var(--icon-instagram);
    }

    &-twitter {
      background-image: var(--icon-twitter);
    }

    &-refresh {
      background-image: var(--icon-refresh);
    }

    &-refresh-rd {
      background-image: var(--icon-refresh-rd);
    }

    &-dump {
      background-image: var(--icon-dump);
    }

    &-telephone {
      background-image: var(--icon-telephone);
    }

    &-enter {
      background-image: var(--icon-enter);
    }

    &-edit {
      background-image: var(--icon-edit);
    }

    &-mail-box {
      background-image: var(--icon-mail-box);
    }

    &-mail-box-notification {
      background-image: var(--icon-mail-box-notification);
    }

    &-config {
      background-image: var(--icon-config);
    }

    &-3-point {
      background-image: var(--icon-3-point);
    }

    &-login-user {
      background-image: var(--icon-login-user);
    }

    &-institution {
      background-image: var(--icon-institution);
    }

    &-clipboard {
      background-image: var(--icon-clipboard);
    }

    &-share {
      background-image: var(--icon-share);
    }

    &-cancel {
      background-image: var(--icon-cancel);
    }

    &-cancel-rd {
      background-image: var(--icon-cancel-rd);
    }

    &-confirm {
      background-image: var(--icon-confirm);
    }

    &-confirm-rd {
      background-image: var(--icon-confirm-rd);
    }

    &-hammer {
      background-image: var(--icon-hammer);
    }

    &-calendar {
      background-image: var(--icon-calendar);
    }

    &-clock {
      background-image: var(--icon-clock);
    }

    &-bell-notification {
      background-image: var(--icon-bell-notification);
    }

    &-home-white {
      background-image: var(--icon-home-white);
    }

    &-bag-blue {
      background-image: var(--icon-bag-blue);
    }

    &-beer-blue {
      background-image: var(--icon-beer-blue);
    }

    &-group-white {
      background-image: var(--icon-group-white);
    }

    &-pet-green {
      background-image: var(--icon-pet-green);
    }

    &-cancel-white {
      background-image: var(--icon-cancel-white);
    }

    &-tools-white {
      background-image: var(--icon-tools-white);
    }

    &-minus-red-rd {
      background-image: var(--icon-minus-red-rd);
    }

    &-dump-red {
      background-image: var(--icon-dump-red);
    }

    &-hammer-tools {
      background-image: var(--icon-hammer-tools);
    }

    &-bell-yellow-rd {
      background-image: var(--icon-bell-yellow-rd);
    }

    &-bell-white-rd {
      background-image: var(--icon-bell-white-rd);
    }

    &-alert-red-rd {
      background-image: var(--icon-alert-red-rd);
    }

    &-alert-yellow-rd {
      background-image: var(--icon-alert-yellow-rd);
    }

    &-bell-notification-blue-rd {
      background-image: var(--icon-bell-notification-blue-rd);
    }

    &-cancel-red-rd {
      background-image: var(--icon-cancel-red-rd);
    }

    &-confirm-green-rd {
      background-image: var(--icon-confirm-green-rd);
    }

    &-confirm-gray-rd {
      background-image: var(--icon-confirm-gray-rd);
    }

    &-clock-gray {
      background-image: var(--icon-clock-gray);
    }

    &-3-line-white {
      background-image: var(--icon-3-line-white);
    }

    &-hammer-white {
      background-image: var(--icon-hammer-white);
    }

    &-content-white {
      background-image: var(--icon-content-white);
    }

    &-question-white {
      background-image: var(--icon-question-white);
    }

    &-client-white {
      background-image: var(--icon-client-white);
    }

    &-client {
      background-image: var(--icon-client);
    }

    &-user-white {
      background-image: var(--icon-user-white);
    }

    &-plus-white-rd {
      background-image: var(--icon-plus-white-rd);
    }

    &-cancel-white-rd {
      background-image: var(--icon-cancel-white-rd);
    }

    &-exit-blue {
      background-image: var(--icon-exit-blue);
    }

    &-bell-blue-rd {
      background-image: var(--icon-bell-blue-rd);
    }

    &-bar-chart-priority-4 {
      background-image: var(--icon-bar-chart-priority-4);
    }

    &-alert-white-rd {
      background-image: var(--icon-alert-white-rd);
    }

    &-bell-notification-white-rd {
      background-image: var(--icon-bell-notification-white-rd);
    }
  }

  /*CARD*/

  .card {
    background: var(--color-white);
    border: 1px solid var(--color-disabled);
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: auto;
    height: auto;
    padding: var(--space-inset-md);

    &.card-no-border {
      background: var(--color-white);
      width: auto;
      height: auto;
      border: none;
      box-shadow: none;
      padding: 0px;
    }

    #title_section_1 {
      font-weight: 700;
    }

    #title_section_2 {
      font-weight: 300;
      margin-left: var(--space-md);
    }

    #title_section_3 {
      font-weight: 300 !important;
      margin: 0px !important;
    }

    #p_1 {
      font-weight: 600 !important;
      font-size: 14px;
    }

    #title_image_1 {
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      color: var(--color-white);
    }

    #card_section_1 {
      margin-top: var(--space-md) !important;
    }

    #card_section_2 {
      margin-top: var(--space-sm) !important;
      margin-left: var(--space-md) !important;
    }

    #list_1 {
      margin: 0px !important;

      .list-item {
        margin: 0px !important;
      }
    }

    #image_card_1 {
      border-bottom-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    &#card_identity {
      width: 452px;
      height: 211px;
    }

    &#card_1 {
      padding: 0px;

      .card-wrapper {
        padding: var(--space-inset-sm);
      }
    }

    .card-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.5px solid var(--color-disabled);
      padding: var(--space-inset-sm);
      margin: var(--space-stack-md);
    }

    .card-body {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;

      .card-section {
        display: flex;
        flex-direction: column;
        width: auto;
        box-sizing: content-box;

        &.notification-not-read {
          background-color: var(--color-tertiary-60);
        }

        &.notification-readed {
          background-color: #c2fdff40;
        }

        &>* {
          margin: var(--space-stack-md);
        }
      }
    }

    .card-notification {
      @extend .btn;
      position: absolute;
      width: 100%;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      height: 45px;
      background-color: var(--color-tertiary-40);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .card-notification-center {
      @extend .card-notification;
      background-color: var(--color-tertiary-40);
      border-radius: 0;
      top: 50%;
      transform: translatey(-50%);
    }

    .card-notification-center-warning {
      @extend .card-notification-center;
      background-color: var(--color-warning);
    }

    .card-notification-center-priority-1 {
      @extend .card-notification-center;
      background-color: var(--color-black);
    }

    .card-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  @media screen and (max-width: 576px) {
    .card {
      background: var(--color-white);
      border: 1px solid var(--color-disabled);
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      width: 330px;
      height: auto;
      padding: var(--space-inset-sm);
    }
  }

  /*LIST*/

  ul.list {
    list-style: none;
    padding: 0;
    margin: 0;

    li.list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: var(--space-sm);

      p.half-border-bottom {
        padding-bottom: var(--space-sm);
        float: left;
        background-image: linear-gradient(var(--color-neutral-60),
            var(--color-neutral-60));
        /* no color change, just one color */
        background-size: 50% 1px;
        /* 2px is thickness of the border */
        background-repeat: no-repeat;
        /* don't repeat background */
        background-position: 100% 100%;
      }
    }
  }

  /*IMAGEs*/

  img {
    &.img-square {
      border-radius: 4px;
      aspect-ratio: 4/4;
      object-fit: cover;
    }

    &.img-rectangle-sm {
      border-radius: 4px;
      aspect-ratio: 4/3;
      object-fit: cover;
    }

    &.img-rectangle-lg {
      border-radius: 4px;
      aspect-ratio: 16/9;
      object-fit: cover;
    }

    &.img-circle {
      border-radius: 50%;
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }

  /*BUTTON*/

  button {
    &.btn:disabled {
      background-color: var(--color-disabled) !important;
      color: var(--color-neutral-60) !important;
    }

    &.btn {
      border-radius: 12px;
      border: 0px;
      background-color: var(--color-tertiary-40);
      padding: var(--space-inset-squish-md);
      color: var(--color-white) !important;
      width: auto;

      &-sm {
        @extend .btn;
        padding: var(--space-inset-sm) !important;
      }

      &-md {
        @extend .btn;
        padding-top: var(--space-sm) !important;
        padding-bottom: var(--space-sm) !important;
        padding-left: var(--space-md) !important;
        padding-right: var(--space-md) !important;
      }

      &-lg {
        @extend .btn;
        padding-top: var(--space-md) !important;
        padding-bottom: var(--space-md) !important;
        padding-left: var(--space-lg) !important;
        padding-right: var(--space-lg) !important;
      }

      &.btn-light {
        background-color: var(--color-disabled);
      }

      &.btn-secondary {
        background-color: var(--color-sucess);
      }

      &.btn-danger {
        background-color: var(--color-primary-20);
      }

      &.btn-dark {
        background-color: var(--color-neutral-10);
      }
    }

    &.btn-outline {
      font-family: var(--font-family-helvetica);
      font-weight: 300;
      font-size: 18px;
      background-color: var(--color-white);
      color: var(--color-tertiary-40) !important;
      border: 1px solid var(--color-tertiary-40);
      border-radius: 12px;
      padding: var(--space-inset-squish-md) !important;
      width: auto;

      &-sm {
        @extend .btn-outline;
        padding: var(--space-inset-sm);
      }

      &-md {
        @extend .btn-outline;
        padding-top: var(--space-sm);
        padding-bottom: var(--space-sm);
        padding-left: var(--space-md);
        padding-right: var(--space-md);
      }

      &-lg {
        @extend .btn-outline;
        padding-top: var(--space-md);
        padding-bottom: var(--space-md);
        padding-left: var(--space-lg);
        padding-right: var(--space-lg);
      }

      &.btn-outline-light {
        color: var(--color-white) !important;
        border: 1px solid var(--color-white) !important;
        border-radius: 100px;
      }

      &.btn-outline-secondary {
        color: var(--color-sucess) !important;
        border: 1px solid var(--color-sucess) !important;
      }

      &.btn-outline-danger {
        color: var(--color-primary-20) !important;
        border: 1px solid var(--color-primary-20) !important;
      }

      &.btn-outline-dark {
        color: var(--color-neutral-10) !important;
        border: 1px solid var(--color-neutral-10) !important;
      }

      &.btn-outline-orange {
        color: var(--color-primary-30) !important;
        border: 1px solid var(--color-primary-30) !important;
      }
    }

    &.btn-clear {
      background-color: transparent;
      color: var(--color-tertiary-40);
      border-radius: 100px;
      border: 0px;
      width: fit-content;
      text-transform: uppercase;

      &-sm {
        @extend .btn-clear;
        padding: var(--space-inset-sm);
      }

      &-md {
        @extend .btn-clear;
        padding-top: var(--space-sm);
        padding-bottom: var(--space-sm);
        padding-left: var(--space-md);
        padding-right: var(--space-md);
      }

      &-lg {
        @extend .btn-clear;
        padding-top: var(--space-md);
        padding-bottom: var(--space-md);
        padding-left: var(--space-lg);
        padding-right: var(--space-lg);
      }

      &.btn-clear-light {
        color: var(--color-white) !important;
      }

      &.btn-clear-secondary {
        color: var(--color-sucess) !important;
      }

      &.btn-clear-danger {
        color: var(--color-primary-20) !important;
      }

      &.btn-clear-dark {
        color: var(--color-black) !important;
      }
    }

    &.btn-rounded {
      width: 100%;
      border-radius: 100px;
      border: 0px;
      background-color: var(--color-tertiary-40) !important;
      padding-bottom: var(--space-sm);
      padding-top: var(--space-sm);
      color: var(--color-white) !important;
      width: 100%;
      text-transform: uppercase;

      &.btn-rounded-light {
        background-color: var(--color-disabled) !important;
      }

      &.btn-rounded-secondary {
        background-color: var(--color-sucess) !important;
      }

      &.btn-rounded-danger {
        background-color: var(--color-primary-20) !important;
      }

      &.btn-rounded-dark {
        background-color: var(--color-black) !important;
      }
    }

    &.btn-direction {
      border-radius: 5px;
      border: 0px;
    }

    &.btn-extra {
      padding: var(--space-inset-strech-sm);
      width: auto;
      min-width: 0px;
      height: auto !important;

      .mdc-button__label {
        display: flex !important;
        align-items: center !important;
      }

      .ic {
        width: 12px;
        height: 12px;
        margin: 0px;
      }

      .ic:only-child {
        margin: 0px !important;
      }

      .ic:first-child {
        margin-right: 10px;
      }

      .ic:last-child {
        margin-left: 10px;
      }
    }

    &.btn-direction {
      @extend .btn-extra;
      background-color: #f4f4f4;
    }

    &.btn-icon {
      display: flex;
      align-items: center;
      color: none !important;
      min-width: 0;
      height: fit-content !important;
      width: auto !important;
      padding: 8px;
      border-radius: 50%;

      .mdc-button__label {
        display: flex;
        align-items: center;
      }

      .mat-icon {
        margin: 0;
      }
    }

    &.secondary-button {
      border: solid 1px var(--color-neutral-60) !important;
      border-radius: 12px !important;
      margin: unset;
      height: 40px !important;
      padding-left: 16px !important;

      mat-icon {
        order: 1;
        margin-left: 8px !important;
        margin-right: 0px !important;
      }
    }

    &.tertiary-button {
      @extend .secondary-button;
      border: solid 1px var(--color-tertiary-40) !important;
      color: var(--color-tertiary-40) !important;
    }
  }

  /*BADGES*/

  .badge {
    @extend .btn;
    border-radius: 100px;
    border: 0px;
    padding: var(--space-xx-sm) var(--space-x-sm);
    background-color: var(--color-tertiary-40);
    width: fit-content;
    color: var(--color-white);

    &.badge-danger {
      background-color: var(--color-primary-20);
      color: var(--color-white);
    }

    &.badge-priority-4 {
      background-color: var(--color-tertiary-60);
      color: var(--color-black);
    }

    &.badge-priority-2 {
      background-color: var(--color-priority-2);
      color: var(--color-black);
    }
  }

  /*INPUT*/

  .step-group {
    display: flex;
    align-items: center;

    .step-item {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 60%;
      border: 1px solid var(--color-black);
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: var(--space-sm);
      width: 76px;

      .step-indicator {
        margin: var(--space-stack-md);

        &.parked {
          color: var(--color-primary-30);
        }
      }
    }

    .stick {
      content: "";
      background-color: var(--color-neutral-60);
      height: 2px;
      width: 55px;
      display: block;
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }
  }

  /*TABLE*/

  table.table {
    th {
      @extend .btn;
      border-bottom: 0.5px solid var(--color-disabled);
    }

    td {
      @extend .sub-1;
    }

    button {
      margin: auto;
    }
  }

  #table_1 {
    border-collapse: collapse;

    tr {
      border-bottom: 0.5px solid var(--color-disabled);

      td {
        @extend .sub-1;
        padding: var(--space-sm) 0px;
        width: fit-content;
      }
    }

    tr:last-child {
      border-bottom: 0px;
    }
  }

  .mat-expansion-panel {
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2) !important;
    border-radius: 4px !important;

    #icon {
      font-size: 15px;
      width: 15px;
      height: 15px;
    }

    .mat-expansion-panel-header {
      padding: 15px;
      height: auto;

      .mat-expansion-panel-header-title {
        margin: 0;

        h6 {
          font-family: 'Helvetica Neue';
          font-size: 16px !important;
          font-weight: 500 !important;
        }
      }

      &.mat-expanded {
        #icon {
          transform: rotate(180deg);
        }
      }

      .mat-content {
        margin: 0;
      }
    }

    .mat-accordion {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .mat-expansion-panel-body {
      padding: 0;
      margin-right: 15px;
      margin-left: 36px;
      margin-bottom: 10px;
    }

    &.item {
      border: 0px;
      box-shadow: none !important;

      .mat-expansion-panel-header {
        padding: 0;

        h6 {
          font-family: var(--font-family-helvetica);
          font-size: 16px !important;
          font-weight: 400 !important;
        }
      }

      .mat-expansion-panel-body {
        margin-left: 0px;
        margin-right: 0px;
      }

      .mat-expansion-panel-header.mat-expanded {
        .mat-expansion-panel-header-title {
          border-bottom: 0px !important;
          padding-bottom: 5px;
        }
      }

      .mat-expansion-panel-header-title {
        margin: 0;
      }
    }

    .mat-expansion-panel-header.mat-expanded {
      .mat-expansion-panel-header-title {
        padding-bottom: 5px;
      }
    }

    .mat-expansion-panel-header-title {
      justify-content: space-between;
    }

    .mat-expansion-panel-header-description {
      justify-content: space-between;
    }
  }

  /*BLOCK*/

  section {
    .block-lg {
      &>* {
        margin: var(--space-stack-md);
      }
    }

    .block-sm {
      &>* {
        margin: var(--space-stack-sm);
      }
    }

    .block-rectangle {
      display: flex;
      height: 200px;

      &>* {
        margin: var(--space-inline-sm);
      }

      .content {
        display: flex;
        flex-direction: column;
      }

      #badge_1 {
        position: absolute;
        right: 10px;
        bottom: 5%;
      }

      #div_1 {
        margin: auto 0px 0px;
      }

      #icon_1 {
        width: 17px;
        height: 17px;
      }

      #span_1 {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #bdbdbd;
      }
    }

    .news {
      .title {
        display: flex;
        align-items: center;
        margin: var(--space-stack-md);
      }

      .container {
        padding-left: var(--space-lg);

        .content {
          display: flex;
          flex-direction: column;

          &>* {
            margin: var(--space-stack-sm);
          }

          .title {
            font-family: var(--font-family-helvetica);
            font-weight: 600;
            font-size: 18px;
            line-height: 99%;
            color: #3f24ab;
          }

          p {
            font-family: var(--font-family-helvetica);
            font-weight: 300;
            font-size: 14px;
            line-height: 160%;
          }
        }
      }
    }
  }

  .not-found {
    font-weight: 400;
    font-size: 20px;

    .title {
      font-weight: 600;
      font-size: 24px;
      margin: var(--space-stack-md);
    }
  }

  /*MODAL*/

  @media screen and (min-width: 576px) {
    .modal {
      border: 1px solid var(--color-neutral-95);
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      width: 450px;
      height: 250px;
      display: flex;
      flex-direction: column;

      .modal-header {
        border-bottom: 1px solid var(--color-neutral-95);
        display: flex;
        justify-content: center;
        padding: var(--space-sm);
        margin: var(--space-stack-md);

        button {
          position: absolute;
          top: 5px;
          right: 0;
          margin-right: 20px;
        }
      }

      .modal-body {
        padding: var(--space-inset-squish-lg);
        display: flex;
        justify-content: center;
      }

      .modal-footer {
        align-self: flex-end;
        margin-top: auto;
        padding: var(--space-md);
      }

      &-sucess {
        .modal-header {
          color: var(--color-sucess);
        }
      }

      &-confirm {
        .modal-header {
          color: var(--color-black);
        }
      }

      &-error {
        .modal-header {
          position: relative;
          align-items: center;
          color: var(--color-primary-20);
        }
      }

      &-choice {
        .modal-header {
          color: var(--color-black);
          position: relative;
          align-items: center;
          font-weight: 300;
          font-size: 18px;
        }

        .modal-body {
          p {
            font-weight: 300;
            font-size: 22px;
          }
        }

        .modal-footer {
          align-self: normal;
          display: flex;
          justify-content: center;

          button:first-child {
            margin-right: 100px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    .modal {
      border: 1px solid var(--color-neutral-95);
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      width: 263px;
      height: 217px;

      .modal-header {
        border-bottom: 1px solid var(--color-neutral-95);
        display: flex;
        justify-content: center;
        padding: var(--space-sm);
        margin: var(--space-stack-md);
        position: relative;

        .ic {
          position: absolute;
          right: 0;
          margin-right: 20px;
        }
      }

      .modal-body {
        padding: var(--space-inset-squish-md);
        display: flex;
        justify-content: center;

        h5,
        h6,
        span {
          font-weight: 400;
          font-size: 14px;
        }
      }

      .modal-body {
        padding: var(--space-inset-squish-md);
        display: flex;
        justify-content: center;
      }

      .modal-footer {
        align-self: flex-end;
        padding: var(--space-md);
        margin: auto auto 0px auto;
      }

      *>button {
        width: 206px;
        height: 45px;
      }

      &-sucess {
        .modal-header {
          color: var(--color-sucess);
        }
      }

      &-error {
        .modal-header {
          color: var(--color-primary-20);
          align-items: center;

          h6 {
            font-weight: 400;
            font-size: 16px;
          }

          .ic {
            display: none;
          }
        }
      }

      &-choice {
        .modal-header {
          color: var(--color-black);
          position: relative;
          align-items: center;
          font-weight: 300;
          font-size: 18px;
        }

        .modal-footer {
          display: flex;
          justify-content: space-around;
          padding: 0px 0px var(--space-md) 0px;
          width: 100%;

          button {
            width: 102px;
            height: 45px;
          }
        }
      }
    }
  }

  /*NAVIGATION*/

  .pagination-group {
    display: flex;
    align-items: center;

    &>* {
      margin: var(--space-inline-md);
    }

    .pagination-item {
      font-weight: 700;
      font-size: 24px;
      color: var(--color-disabled);
      border: 1px solid var(--color-black);
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: flex;
      justify-content: space-around;
      width: 63px;
      padding: var(--space-sm);

      &.selected {
        color: var(--color-primary-30);
      }
    }
  }

  //MAINTABS//
  @media only screen and (min-width: 600px) {
    .mdc-tab-indicator {
      display: none !important;
    }

    .mat-mdc-tab-body {
      height: fit-content;
      border-bottom: 1px solid var(--color-neutral-95);

      &.no-border {
        border-bottom: none;
      }
    }

    .mat-mdc-tab-body-content {
      padding: 5px;
      box-sizing: border-box;
    }

    .mat-mdc-tab {

      span {
        font-family: var(--font-family-helvetica);
        font-size: 20px;
        font-weight: 500;
      }

      &.low-text {
        span {
          font-size: 14px;
        }
      }

      &.mdc-tab--active {
        border-bottom: 2px solid var(--color-primary-40);

        span {
          color: var(--color-black) !important;
        }

        background-color: transparent !important;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .mat-mdc-tab-body {
      height: fit-content;
      border-bottom: 1px solid var(--color-neutral-95);

      &.no-border {
        border-bottom: none;
      }

    }

    .mat-mdc-tab-label-container {
      width: 100%;

      .mat-mdc-tab-list {
        width: 100%;
        border-bottom: 1px solid var(--color-neutral-10);

        .mat-mdc-tab {
          width: 50%;

          span {
            font-family: var(--font-family-helvetica);
            font-size: 14px;
            font-weight: 500;
          }

          &.mdc-tab--active {
            border-bottom: 2px solid var(--color-primary-40);

            span {
              color: var(--color-black) !important;
            }

            background-color: transparent !important;
          }
        }
      }
    }

    .mdc-tab-indicator__content {
      display: none;
    }

    .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
      display: none !important;
    }
  }


  //BREADCRUMB//
  .breadcrumb-group {
    display: flex;
    align-items: center;

    &>* {
      margin: var(--space-inline-md);
    }

    .previous-page {
      @extend .special-button;
    }

    .current-page {
      @extend .btn;
    }
  }

  //NOTIFICATION//

  .bar-notification {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);

    &>* {
      margin: 0px 15px;
    }

    &-blue {
      background-color: var(--color-tertiary-40);
    }

    &-orange {
      background-color: var(--color-primary-30);
    }
  }
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgb(128, 203, 196);
}

button {
  margin: auto;
}

#table_1 {
  border-collapse: collapse;

  tr {
    border-bottom: 0.5px solid var(--color-disabled);

    td {
      @extend .sub-1;
      padding: var(--space-sm) 0px;
      width: fit-content;
    }
  }

  tr:last-child {
    border-bottom: 0px;
  }
}

/*BLOCK*/

section {
  .block-lg {
    &>* {
      margin: var(--space-stack-md);
    }
  }

  .block-sm {
    &>* {
      margin: var(--space-stack-sm);
    }
  }

  .block-rectangle {
    display: flex;
    height: 200px;

    &>* {
      margin: var(--space-inline-sm);
    }

    .content {
      display: flex;
      flex-direction: column;
    }

    #badge_1 {
      position: absolute;
      right: 10px;
      bottom: 5%;
    }

    #div_1 {
      margin: auto 0px 0px;
    }

    #icon_1 {
      width: 17px;
      height: 17px;
    }

    #span_1 {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #bdbdbd;
    }
  }

  .news {
    .title {
      display: flex;
      align-items: center;
      margin: var(--space-stack-md);
    }

    .container {
      padding-left: var(--space-lg);

      .content {
        display: flex;
        flex-direction: column;

        &>* {
          margin: var(--space-stack-sm);
        }

        .title {
          font-family: var(--font-family-helvetica);
          font-weight: 600;
          font-size: 18px;
          line-height: 99%;
          color: #3f24ab;
        }

        p {
          font-family: var(--font-family-helvetica);
          font-weight: 300;
          font-size: 14px;
          line-height: 160%;
        }
      }
    }
  }
}

.not-found {
  font-weight: 400;
  font-size: 20px;

  .title {
    font-weight: 600;
    font-size: 24px;
    margin: var(--space-stack-md);
  }
}

/*MODAL*/

@media screen and (min-width: 576px) {
  .modal {
    border: 1px solid var(--color-neutral-95);
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    width: 450px;
    height: 250px;
    display: flex;
    flex-direction: column;

    .modal-header {
      border-bottom: 1px solid var(--color-neutral-95);
      display: flex;
      justify-content: center;
      padding: var(--space-sm);
      margin: var(--space-stack-md);

      button {
        position: absolute;
        top: 5px;
        right: 0;
        margin-right: 20px;
      }
    }

    .modal-body {
      padding: var(--space-inset-squish-lg);
      display: flex;
      justify-content: center;
    }

    .modal-footer {
      align-self: flex-end;
      margin-top: auto;
      padding: var(--space-md);
    }

    &-sucess {
      .modal-header {
        color: var(--color-sucess);
      }
    }

    &-confirm {
      .modal-header {
        color: var(--color-black);
      }
    }

    &-error {
      .modal-header {
        position: relative;
        align-items: center;
        color: var(--color-primary-20);
      }
    }

    &-choice {
      .modal-header {
        color: var(--color-black);
        position: relative;
        align-items: center;
        font-weight: 300;
        font-size: 18px;
      }

      .modal-body {
        p {
          font-weight: 300;
          font-size: 22px;
        }
      }

      .modal-footer {
        align-self: normal;
        display: flex;
        justify-content: center;

        button:first-child {
          margin-right: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .modal {
    border: 1px solid var(--color-neutral-95);
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    width: 263px;
    height: 217px;

    .modal-header {
      border-bottom: 1px solid var(--color-neutral-95);
      display: flex;
      justify-content: center;
      padding: var(--space-sm);
      margin: var(--space-stack-md);
      position: relative;

      .ic {
        position: absolute;
        right: 0;
        margin-right: 20px;
      }
    }

    .modal-body {
      padding: var(--space-inset-squish-md);
      display: flex;
      justify-content: center;

      h5,
      h6,
      span {
        font-weight: 400;
        font-size: 14px;
      }
    }

    .modal-body {
      padding: var(--space-inset-squish-md);
      display: flex;
      justify-content: center;
    }

    .modal-footer {
      align-self: flex-end;
      padding: var(--space-md);
      margin: auto auto 0px auto;
    }

    *>button {
      width: 206px;
      height: 45px;
    }

    &-sucess {
      .modal-header {
        color: var(--color-sucess);
      }
    }

    &-error {
      .modal-header {
        color: var(--color-primary-20);
        align-items: center;

        h6 {
          font-weight: 400;
          font-size: 16px;
        }

        .ic {
          display: none;
        }
      }
    }

    &-choice {
      .modal-header {
        color: var(--color-black);
        position: relative;
        align-items: center;
        font-weight: 300;
        font-size: 18px;
      }

      .modal-footer {
        display: flex;
        justify-content: space-around;
        padding: 0px 0px var(--space-md) 0px;
        width: 100%;

        button {
          width: 102px;
          height: 45px;
        }
      }
    }
  }
}

/*NAVIGATION*/

.pagination-group {
  display: flex;
  align-items: center;

  &>* {
    margin: var(--space-inline-md);
  }

  .pagination-item {
    font-weight: 700;
    font-size: 24px;
    color: var(--color-disabled);
    border: 1px solid var(--color-black);
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    width: 63px;
    padding: var(--space-sm);

    &.selected {
      color: var(--color-primary-30);
    }
  }
}

//BREADCRUMB//
.breadcrumb-group {
  display: flex;
  align-items: center;

  &>* {
    margin: var(--space-inline-md);
  }

  .previous-page {
    @extend .special-button;
  }

  .current-page {
    @extend .btn;
  }
}

//NOTIFICATION//

.bar-notification {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);

  &>* {
    margin: 0px 15px;
  }

  &-blue {
    background-color: var(--color-tertiary-40);
  }

  &-orange {
    background-color: var(--color-primary-30);
  }
}


.mdc-tab-indicator__content--underline {
  border-color: var(--color-primary-30) !important;
}


.mdc-tab__text-label {
  font-family: var(--font-family-helvetica);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 146.5%;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: rgb(29, 135, 229);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-ripple .mat-ripple-element {
  background-color: rgba(29, 135, 229, 0.2);
}


// Typography

/* Title */

h6.title-large {
  //styleName: Title/Large;
  font-family: var(--font-family-helvetica);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}


.body-medium {
  font-family: var(--font-family-helvetica);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;

}

.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  --mdc-circular-progress-active-indicator-color: var(--color-primary-30);
}

.remove-bottom-space-input .mat-mdc-form-field-subscript-wrapper{
  display: none;
}

.action-row-end-items {
  justify-content: flex-end;
  gap: 8px;

  .mat-mdc-button-base {
    margin: inherit;
  }
}

.news {
  &-bullet {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-align: left;
    color: var(--color-neutral-40);
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &::before {
      content: "";
      display: inline-block;
      margin-right: 5px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: var(--color-primary-30);
    }
  }

  &-date {
    color: var(--color-neutral-60);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    mat-icon {
      font-size: 24px;
      margin-right: 8px;
    }
  }
}
